import React, { createContext, useState, ReactNode } from "react";
import { LensTechnogolyData } from "../utils/commonInterfaces";
import { singleVisionData } from "../utils/singleVisionData";

interface TechContextType {
  technology: LensTechnogolyData[];
  setTechnology: (tech: LensTechnogolyData[]) => void;
}

export const TechContext = createContext<TechContextType | undefined>(
  undefined
);

interface TechProviderProps {
  children: ReactNode;
}

export const TechProvider: React.FC<TechProviderProps> = ({ children }) => {
  const [technology, setTechnology] =
    useState<LensTechnogolyData[]>(singleVisionData);

  return (
    <TechContext.Provider value={{ technology, setTechnology }}>
      {children}
    </TechContext.Provider>
  );
};
