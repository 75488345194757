import React, { Suspense, useRef } from "react";
import { Canvas } from "@react-three/fiber";
import { PerspectiveCamera } from "@react-three/drei";
import * as THREE from "three";
import LensScene from "../LensScene";
import { BackgroundENVModel, LensModel } from "../../utils/commonInterfaces";
import Loader from "../Loader";
import Background from "../Background";
import MeshWithGLBModel from "../MeshWithGLBModel";
import MonitorModel from "../monitorModel";

interface LensLoaderProps {
  BackgroundENVModels: BackgroundENVModel;
  LensModels?: LensModel | null;
  isBookObject: boolean;
  isMobileObject: boolean;
}

const LensLoader: React.FC<LensLoaderProps> = ({
  BackgroundENVModels,
  LensModels,
  isBookObject,
  isMobileObject,
}) => {
  const cameraRef = useRef<THREE.PerspectiveCamera>(null);

  const calculateValues = () => {
    const width = window.innerWidth;
    let translateZ, position;

    if (width < 850) {
      translateZ = 80;
      position = [-42, 4, 0.9];
    } else if (width < 992) {
      translateZ = 72;
      position = [-42, 4, 0.9];
    } else if (width < 1200) {
      translateZ = 70;
      position = [-42, 4, 0.9];
    } else if (width < 1300) {
      translateZ = 65;
      position = [-42, 4, 0.9];
    } else if (width < 1500) {
      translateZ = 60;
      position = [-42, 4, 0.9];
    } else {
      translateZ = 54;
      position = [-42, 4, 0.9];
    }

    return { translateZ, position };
  };

  const { translateZ, position } = calculateValues();

  return (
    <Canvas className="office-env">
      <PerspectiveCamera
        ref={cameraRef}
        makeDefault
        fov={translateZ}
        position={LensModels ? LensModels.position : [-43, 5, 0.9]}
      />
      <ambientLight intensity={0.5} />
      <Suspense fallback={<Loader theme="black_theme" />}>
        <MonitorModel BackgroundENVModels={BackgroundENVModels} />
        <Background BackgroundENVModels={BackgroundENVModels} />
        {LensModels && (
          <>
            <LensScene
              LensModels={LensModels}
              isRotate={!isBookObject && !isMobileObject}
            />
            <MeshWithGLBModel
              isBookObject={isBookObject}
              isMobileObject={isMobileObject}
              LensModels={LensModels}
            />
          </>
        )}
      </Suspense>
    </Canvas>
  );
};

export default LensLoader;
