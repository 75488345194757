import React from "react";
import { useThree } from "@react-three/fiber";
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader";
import { useLoader } from "@react-three/fiber";
import * as THREE from "three";
import { BackgroundENVModel } from "../../utils/commonInterfaces";
import {
  EquirectangularReflectionMapping,
  Texture,
  TextureLoader,
} from "three";

interface HDRLoaderProps {
  BackgroundHDRModels: BackgroundENVModel;
}
const HDRLoader: React.FC<HDRLoaderProps> = ({ BackgroundHDRModels }) => {
  const { scene, gl } = useThree();
  // const path =
  //   BackgroundHDRModels.type === "hdr"
  //     ? `/hdr/${BackgroundHDRModels.model}.hdr`
  //     : "/hdr/Outdoor.hdr";
  // const hdrTexture = useLoader(RGBELoader, path);
  const path =
  BackgroundHDRModels.type === "jpg"
    ? `/hdr/${BackgroundHDRModels.model}.jpg`
    : "/hdr/Indoor_jpg_3.jpg";
const hdrTexture = useLoader(TextureLoader, path) as Texture;
  hdrTexture.mapping = THREE.EquirectangularReflectionMapping;

  React.useEffect(() => {
    scene.background = hdrTexture;
    scene.environment = hdrTexture;
    gl.toneMapping = THREE.ACESFilmicToneMapping;
    gl.toneMappingExposure = 0.9;
    return () => {
      hdrTexture.dispose();
      scene.background = null;
      scene.environment = null;
    };
  }, [hdrTexture, scene, gl]);

  return null;
};
export default HDRLoader;