import React, { useRef, useEffect, useContext, useState } from "react";
import { Card, Slider } from "@mui/material";
import { TechContext } from "../../context/technologyContext";
import { LensTechnogolyData } from "../../utils/commonInterfaces";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { TiArrowLeftThick } from "react-icons/ti";
import { IoMdArrowDropup } from "react-icons/io";
interface InfographicProps {
  keyName: string;
  isMaterialThicknessOpen: boolean;
  setIsMaterialThicknessOpen: (info: boolean) => void;
}

interface materialList {
  defaultMaterial: LensTechnogolyData[];
  selectedMaterial: LensTechnogolyData[];
}

interface materialLensThicknes {
  centerThickness: number;
  edgeThickness: number;
  sph: number;
  diameter: number;
  index: number;
  svgPath: string;
}

const lensData: Record<string, materialLensThicknes[]> = {
  classic: [
    {
      centerThickness: 1.8,
      edgeThickness: 5.92,
      diameter: 70,
      index: 1.49,
      sph: -3,
      svgPath:
        "M 25 0 L 95 0 C 25 410 25 410 95 820 L 25 820 C 0 410 0 410 25 0",
    },
    {
      centerThickness: 3.93,
      edgeThickness: 0.65,
      diameter: 65,
      index: 1.49,
      sph: 3,
      svgPath:
        "M1.5,414.9C1.5,146.7,64.3,120.1,78.60000000000001,1.7V828C64.3,732.7,1.5,583.1,1.5,414.9Z",
    },
  ],
  polycarbonate: [
    {
      centerThickness: 1.15,
      edgeThickness: 6.93,
      diameter: 70,
      index: 1.59,
      sph: -5,
      svgPath: "M 10 0 L 45 0 C 0 65 0 185 45 249 L 10 249 C 0 170 0 81 10 0",
    },
    {
      centerThickness: 1.15,
      edgeThickness: 4.64,
      diameter: 70,
      index: 1.59,
      sph: -3,
      svgPath: "M 10 0 L 35 0 C 0 66 0 182 35 249 L 10 249 C 0 170 0 81 10 0",
    },
    {
      centerThickness: 1.15,
      edgeThickness: 5.26,
      diameter: 70,
      index: 1.59,
      sph: -3.5,
      svgPath: "M 10 0 L 37 0 C 2 69 2 181 37 249 L 10 249 C 0 170 0 81 10 0",
    },
    {
      centerThickness: 3.45,
      edgeThickness: 0.65,
      diameter: 65,
      index: 1.59,
      sph: 3,
      svgPath:
        "M 1.5 414.9 C 1 210 52 115 80 3 V 830 C 64.3 732.7 1.5 583.1 1.5 414.9 Z",
    },
    {
      centerThickness: 3.9,
      edgeThickness: 0.65,
      diameter: 65,
      index: 1.59,
      sph: 3.5,
      svgPath:
        "M 1.5 414.9 C 1 210 52 115 100 1.7 V 828 C 64.3 732.7 1.5 583.1 1.5 414.9 Z",
    },
    {
      centerThickness: 5.31,
      edgeThickness: 0.65,
      diameter: 65,
      index: 1.59,
      sph: 5,
      svgPath:
        "M 1.5 414.9 C 1 210 52 115 120 -1 V 830 C 64.3 732.7 1.5 583.1 1.5 414.9 Z",
    },
  ],
  thinLens: [
    {
      centerThickness: 1.0,
      edgeThickness: 8.27,
      diameter: 70,
      index: 1.67,
      sph: -8,
      svgPath: "M 10 0 L 55 0 C -3 64 -3 187 55 250 L 10 249 C 0 170 0 81 10 0",
    },
    {
      centerThickness: 1.0,
      edgeThickness: 5.36,
      diameter: 70,
      index: 1.67,
      sph: -5,
      svgPath: "M 10 0 L 38 0 C 0 66 0 182 38 249 L 10 249 C 0 170 0 81 10 0",
    },
    {
      centerThickness: 4.08,
      edgeThickness: 0.5,
      diameter: 65,
      index: 1.67,
      sph: 5,
      svgPath:
        "M 1.5 414.9 C 1 210 52 115 100 1.7 V 828 C 64.3 732.7 1.5 583.1 1.5 414.9 Z",
    },
    {
      centerThickness: 6.27,
      edgeThickness: 0.5,
      diameter: 65,
      index: 1.67,
      sph: 8,
      svgPath:
        "M 1.5 414.9 C 1 210 52 115 120 -1 V 830 C 64.3 732.7 1.5 583.1 1.5 414.9 Z",
    },
  ],
  ultraThin: [
    {
      centerThickness: 1.15,
      edgeThickness: 5.95,
      diameter: 70,
      index: 1.74,
      sph: -8.5,
      svgPath: "M 10 0 L 37 0 C 2 69 2 181 37 249 L 10 249 C 0 170 0 81 10 0",
    },
    {
      centerThickness: 6.91,
      edgeThickness: 0.5,
      diameter: 70,
      index: 1.74,
      sph: -8,
      svgPath: "M 10 0 L 37 0 C 2 69 2 181 37 249 L 10 249 C 0 170 0 81 10 0",
    },
    {
      centerThickness: 4.5,
      edgeThickness: 0.5,
      diameter: 70,
      index: 1.74,
      sph: -5,
      svgPath: "M 10 0 L 37 0 C 2 69 2 181 37 249 L 10 249 C 0 170 0 81 10 0",
    },
    {
      centerThickness: 1.0,
      edgeThickness: 8.97,
      diameter: 65,
      index: 1.74,
      sph: 5,
      svgPath:
        "M 1.5 414.9 C 1 210 52 115 100 1.7 V 828 C 64.3 732.7 1.5 583.1 1.5 414.9 Z",
    },
    {
      centerThickness: 6.61,
      edgeThickness: 0.5,
      diameter: 65,
      index: 1.74,
      sph: 8,
      svgPath:
        "M 1.5 414.9 C 1 210 52 115 120 -1 V 830 C 64.3 732.7 1.5 583.1 1.5 414.9 Z",
    },
    {
      centerThickness: 1.15,
      edgeThickness: 9.2,
      diameter: 65,
      index: 1.74,
      sph: 8.5,
      svgPath:
        "M 1.5 414.9 C 1 210 52 115 120 -1 V 830 C 64.3 732.7 1.5 583.1 1.5 414.9 Z",
    },
  ],
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const MaterialThicknessComparison: React.FC<InfographicProps> = ({
  keyName,
  isMaterialThicknessOpen,
  setIsMaterialThicknessOpen,
}) => {
  const techContext = useContext(TechContext);
  const [features, setFeatures] = useState<materialList>({
    defaultMaterial: [],
    selectedMaterial: [],
  });
  const [selectedLensMaterial, setSelectedLensMaterial] =
    useState<materialLensThicknes>(lensData["classic"][0]);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (techContext) {
      const techData = techContext.technology;
      const classicMaterial = techData.filter((data) => data.key === "classic");
      const selectedMaterial = techData.filter((data) => data.key === keyName);

      setFeatures({
        defaultMaterial: classicMaterial,
        selectedMaterial: selectedMaterial,
      });
    }
  }, [techContext, keyName]);
  const formatValue = (item: any) => {
    if (item.value === true) return "✔️";
    if (item.value === false) return "❌";
    if (item.value === "") return "-";
    if (
      typeof item.value === "number" &&
      item.displayName === "High Power Suitability" &&
      item.value >= 1 &&
      item.value <= 5
    )
      return "⭐".repeat(item.value);
    return item.value;
  };
  const handleClose = () => {
    setIsMaterialThicknessOpen(false);
  };

  const lensThickness = lensData[keyName];

  if (!lensThickness) {
    console.error(`Key "${keyName}" not found in lensData.`);
    return null;
  }

  const marks = lensThickness.map((ld) => ({
    value: ld.sph,
    label: `${ld.sph}`,
  }));

  const CT =
    selectedLensMaterial.sph > 0
      ? selectedLensMaterial.centerThickness * 20
      : selectedLensMaterial.centerThickness * 10;
  const ET =
    selectedLensMaterial.sph > 0
      ? selectedLensMaterial.edgeThickness
      : selectedLensMaterial.edgeThickness * 20;

  const concaveLensPath = `M 25 0 L ${ET} 0 C ${CT} 410 ${CT} 410 ${ET} 820 L 25 820 C 0 410 0 410 25 0`;
  const convexLensPath = `M1.5,414.9C1.5,146.7,64.3,120.1,${CT},1.7V828C64.3,732.7,1.5,583.1,1.5,414.9Z`;

  const lensMaterial =
    selectedLensMaterial.sph > 0 ? convexLensPath : concaveLensPath;
  const classicMaterial =
    selectedLensMaterial.sph > 0
      ? lensData["classic"][1]
      : lensData["classic"][0];

  const handleChange = (event: Event, newValue: number | number[]) => {
    if (typeof newValue === "number") {
      console.log(newValue);
      const index = lensThickness.findIndex((v) => v.sph == newValue);
      if (index > -1) {
        setSelectedLensMaterial(lensThickness[index]);
      }
    }
  };
  return (
    <React.Fragment>
      <BootstrapDialog
        className="material-compare-customized-dialog"
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={isMaterialThicknessOpen}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          className="material-compare-customized-dialog__close-icon"
          sx={(theme: any) => ({
            position: "absolute",
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <div ref={dropdownRef}>
            {isMaterialThicknessOpen && (
              <>
                <div
                  className={`material-thickness-Comparison ${
                    isMaterialThicknessOpen && "show"
                  }`}
                >
                  <TableContainer component={Paper}>
                    <Card className="material-thickness-Comparison__card">
                      <div className="material-thickness-Comparison__material-title-wrapper">
                        <span className="material-thickness-Comparison__material-title">
                          Classic
                        </span>
                      </div>
                      <svg
                        id="Layer_1"
                        data-name="Layer 1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox={`0 0 162.4 834.8`}
                        width="100"
                        height="200"
                        preserveAspectRatio="xMidYMid meet"
                      >
                        <path
                          d={classicMaterial.svgPath}
                          stroke="#231f20"
                          strokeWidth="0.5"
                          fill="url(#linear-gradient)"
                        />
                        <defs>
                          <linearGradient
                            id="linear-gradient"
                            x1="74.53"
                            y1="875.43"
                            x2="100.74"
                            y2="-81.81"
                          >
                            <stop offset="0%" stopColor="#87c1e9" />
                            <stop offset="100%" stopColor="#86c1e9" />
                          </linearGradient>
                        </defs>
                      </svg>
                      <TiArrowLeftThick />
                      <span className="material-thickness-Comparison__center-thickness">
                        {classicMaterial.centerThickness} mm
                      </span>
                      <div className="material-thickness-Comparison__edge-thickness">
                        <IoMdArrowDropup />
                      </div>
                      <div className="material-thickness-Comparison__edge-thickness">
                        <span>{classicMaterial.edgeThickness} mm</span>
                      </div>
                    </Card>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Features</TableCell>
                          <TableCell>Classic</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {features.defaultMaterial.map((row, index) => (
                          <TableRow key={index}>
                            <TableCell className="material-thickness-Comparison__table-data">
                              {row.displayName}
                            </TableCell>
                            <TableCell className="material-thickness-Comparison__table-data">
                              {formatValue(row)}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  {keyName !== "classic" && (
                    <TableContainer component={Paper}>
                      <Card className="material-thickness-Comparison__card">
                        <div className="material-thickness-Comparison__material-title-wrapper">
                          <span className="material-thickness-Comparison__material-title">
                            {keyName}
                          </span>
                        </div>
                        <svg
                          id="Layer_1"
                          data-name="Layer 1"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox={`0 0 162.4 834.8`}
                          width="100"
                          height="200"
                          preserveAspectRatio="xMidYMid meet"
                        >
                          <path
                            d={lensMaterial}
                            stroke="#231f20"
                            strokeWidth="0.5"
                            fill="url(#linear-gradient)"
                          />
                          <defs>
                            <linearGradient
                              id="linear-gradient"
                              x1="74.53"
                              y1="875.43"
                              x2="100.74"
                              y2="-81.81"
                            >
                              <stop offset="0%" stopColor="#87c1e9" />
                              <stop offset="100%" stopColor="#86c1e9" />
                            </linearGradient>
                          </defs>
                        </svg>
                        <TiArrowLeftThick />
                        <span className="material-thickness-Comparison__center-thickness">
                          {selectedLensMaterial.centerThickness} mm
                        </span>
                        <div className="material-thickness-Comparison__edge-thickness">
                          <IoMdArrowDropup />
                        </div>
                        <div className="material-thickness-Comparison__edge-thickness">
                          <span>{selectedLensMaterial.edgeThickness} mm</span>
                        </div>
                        <div className="mx-5">
                          <Slider
                            step={0.5}
                            min={marks[0].value}
                            max={marks[marks.length - 1].value}
                            defaultValue={marks[0].value}
                            // getAriaValueText={(v:number) => `${v} SPH`}
                            valueLabelDisplay="on"
                            onChange={handleChange}
                            marks={marks}
                          />
                        </div>
                      </Card>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Features</TableCell>
                            <TableCell>{keyName}</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {features.selectedMaterial.map((row, index) => (
                            <TableRow key={index}>
                              <TableCell className="material-thickness-Comparison__table-data">
                                {row.displayName}
                              </TableCell>
                              <TableCell className="material-thickness-Comparison__table-data">
                                {formatValue(row)}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}
                </div>
              </>
            )}
          </div>
        </DialogContent>
      </BootstrapDialog>
    </React.Fragment>
  );
};

export default MaterialThicknessComparison;
