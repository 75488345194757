import React, { useState, useRef, useEffect } from "react";
import { InfoAnimation } from "../../context/infoAnimContext";
import AccordionComponent from "../../widgets/Accordion";

interface InfoLayoutProps {
  animationInfo: InfoAnimation[];
  isCompareTech: boolean;
}

const InfoLayout: React.FC<InfoLayoutProps> = ({
  animationInfo,
  isCompareTech,
}) => {
  const [toggle, setToggle] = useState(false);
  const videoRefs = useRef<(HTMLVideoElement | null)[]>([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const video = entry.target as HTMLVideoElement;

          if (entry.isIntersecting) {
            const videoSrc = video.getAttribute("data-src");
            if (videoSrc && !video.src) {
              video.src = videoSrc;
            }
            if (video.paused && !video.ended) {
              video.play().catch((error) => {
                console.error("Error playing video:", error);
              });
            }
          } else {
            if (!video.paused) {
              video.pause();
            }
          }
        });
      },
      { threshold: 0.5 }
    );

    videoRefs.current.forEach((videoRef) => {
      if (videoRef) {
        observer.observe(videoRef);
      }
    });

    return () => {
      observer.disconnect();
    };
  }, [animationInfo]);

  useEffect(() => {
    if (toggle) {
      setToggle(false);
    }
  }, [isCompareTech]);

  return (
    <div className="info-layout-wrapper">
      <div className="info-layout info-layout_show">
        <div className={`info-layout__${"compare-disable"}`}>
          {animationInfo?.map((infoAnim, index) => (
            <div key={index} className="row info-layout_row m-1">
              {infoAnim.animationSource && (
                <div className="col-12 info-layout_animation-area info-layout__half-video-layout">
                  <video
                    id={`${index}`}
                    ref={(el) => (videoRefs.current[index] = el)}
                    data-src={infoAnim.animationSource}
                    style={{
                      width: "100%",
                    }}
                    muted
                    loop
                    controls
                  />
                </div>
              )}
              <div className="col-12 info-layout_info-area info-layout__half-video-layout">
                <AccordionComponent benefits={infoAnim?.benefits!} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default InfoLayout;
