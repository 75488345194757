import React, { createContext, useState, ReactNode } from "react";

interface TreatmentContextType {
  selectedTreatment: string;
  setSelectedTreatment: (treatment: string) => void;
}

export const TreatmentContext = createContext<TreatmentContextType>({
  selectedTreatment: "",
  setSelectedTreatment: () => {},
});

interface TreatmentProviderProps {
  children: ReactNode;
}

export const TreatmentProvider: React.FC<TreatmentProviderProps> = ({
  children,
}) => {
  const [selectedTreatment, setSelectedTreatment] = useState<string>("");

  return (
    <TreatmentContext.Provider
      value={{ selectedTreatment, setSelectedTreatment }}
    >
      {children}
    </TreatmentContext.Provider>
  );
};
