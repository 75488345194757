import React, { createContext, useState, ReactNode } from "react";
import { ColorEntry } from '../utils/commonInterfaces';
interface ColorContextType {
  selectedColor: ColorEntry | null;
  setSelectedColor: (color: ColorEntry) => void;
}

export const ColorContext = createContext<ColorContextType>({
  selectedColor: null,
  setSelectedColor: () => {},
});

interface ColorProviderProps {
  children: ReactNode;
}

export const ColorProvider: React.FC<ColorProviderProps> = ({ children }) => {
  const [selectedColor, setSelectedColor] = useState<ColorEntry | null>(null);

  return (
    <ColorContext.Provider value={{ selectedColor, setSelectedColor }}>
      {children}
    </ColorContext.Provider>
  );
};
