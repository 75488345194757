import { LensTechnogolyData } from "./commonInterfaces";
import ultimaAnimation from "../assets/animations/Ultima-technology.mp4";
import StabilizeAnimation from "../assets/animations/stabilization.mp4";
import BlendTechAnimation from "../assets/animations/Blend technology-new.mp4";
import EyeGaze from "../assets/animations/eyeGaze.mp4";

export const ultimaData: LensTechnogolyData[] = [
  {
    key: "techName",
    value: "Ultima",
    displayName: "Ultima",
    animationSource: ultimaAnimation,
    bgENVName: "Outdoor-view",
    isLensColor: false,
  },
  { key: "overallRating", value: "", displayName: "Over All Rating" },
  {
    key: "classic",
    value: "",
    displayName: "Refractive Index",
  },
  {
    key: "classic",
    value: "-8.00 to +8.00 Upto -4 cyl",
    defaultRange: { min: -8.0, max: 6.5 },
    clearRange: { min: -8.0, max: 8.0 },
    displayName: "Power Range",
  },
  {
    key: "classic",
    value: false,
    displayName: "Shatter Resistance",
  },
  {
    key: "classic",
    value: "",
    displayName: "Thickness Compared to Classic Lens",
  },
  {
    key: "classic",
    value: false,
    displayName: "Suitability For Rimless Frames",
  },
  {
    key: "classic",
    value: "",
    displayName: "High Power Suitability",
  },
  {
    key: "polycarbonate",
    value: "",
    displayName: "Refractive Index",
  },
  {
    key: "polycarbonate",
    value: "",
    defaultRange: { min: -9.0, max: 6.5 },
    displayName: "Power Range",
  },
  {
    key: "polycarbonate",
    value: "",
    displayName: "Shatter Resistance",
  },
  {
    key: "polycarbonate",
    value: "",
    displayName: "Thickness Compared to Classic Lens",
  },
  {
    key: "polycarbonate",
    value: "",
    displayName: "Suitability For Rimless Frames",
  },
  {
    key: "polycarbonate",
    value: "",
    displayName: "High Power Suitability",
  },
  {
    key: "thinLens",
    value: 1.67,
    displayName: "Refractive Index",
  },
  {
    key: "thinLens",
    value: "-13.00 to +8.50 Upto -6 cyl",
    clearRange: { min: -15.0, max: 9.25 },
    defaultRange: { min: -15.0, max: 9.25 },
    displayName: "Power Range",
  },
  {
    key: "thinLens",
    value: false,
    displayName: "Shatter Resistance",
  },
  {
    key: "thinLens",
    value: "",
    displayName: "Thickness Compared to Classic Lens",
  },
  {
    key: "thinLens",
    value: "",
    displayName: "Suitability For Rimless Frames",
  },
  {
    key: "thinLens",
    value: 4,
    displayName: "High Power Suitability",
  },
  {
    key: "ultraThin",
    value: 1.74,
    displayName: "Refractive Index",
  },
  {
    key: "ultraThin",
    value: "-16.00 to _9.00 Upto -4 cyl",
    clearRange: { min: -14, max: 10.75 },
    displayName: "Power Range",
  },
  {
    key: "ultraThin",
    value: false,
    displayName: "Shatter Resistance",
  },
  {
    key: "ultraThin",
    value: "",
    displayName: "Thickness Compared to Classic Lens",
  },
  {
    key: "ultraThin",
    value: "",
    displayName: "Suitability For Rimless Frames",
  },
  {
    key: "ultraThin",
    value: 5,
    displayName: "High Power Suitability",
  },
  {
    key: "index1.53",
    value: "",
    displayName: "Refractive Index",
  },
  {
    key: "index1.53",
    value: "",
    defaultRange: { min: -9.0, max: 6.5 },
    displayName: "Power Range",
  },
  {
    key: "index1.53",
    value: "",
    displayName: "Shatter Resistance",
  },
  {
    key: "index1.53",
    value: "",
    displayName: "Thickness Compared to Classic Lens",
  },
  {
    key: "index1.53",
    value: "",
    displayName: "Suitability For Rimless Frames",
  },
  {
    key: "index1.53",
    value: "",
    displayName: "High Power Suitability",
  },
  {
    key: "index1.6",
    value: 1.6,
    displayName: "Refractive Index",
  },
  {
    key: "index1.6",
    value: "-11.00 to +7.00 Upto -4 cyl",
    clearRange: { min: -15, max: 8 },
    defaultRange: { min: -15, max: 8 }, //upto -5 cyl
    displayName: "Power Range",
  },
  {
    key: "index1.6",
    value: "",
    displayName: "Shatter Resistance",
  },
  {
    key: "index1.6",
    value: "",
    displayName: "Thickness Compared to Classic Lens",
  },
  {
    key: "index1.6",
    value: "",
    displayName: "Suitability For Rimless Frames",
  },
  {
    key: "index1.6",
    value: "",
    displayName: "High Power Suitability",
  },
  {
    key: "coating",
    value: "",
    displayName: "HC",
  },
  {
    key: "coating",
    value: "",
    displayName: "Adv",
  },
  {
    key: "coating",
    value: "",
    displayName: "HMC",
  },
  {
    key: "coating",
    value: "blueTech+",
    displayName: "BlueTech+",
  },
  {
    key: "coating",
    value: "drivez",
    displayName: "DrivEZ",
  },
  {
    key: "coating",
    value: "clearSight",
    displayName: "ClearSight",
  },
  {
    key: "coating",
    value: "clearSightZ",
    displayName: "ClearSightZ",
  },
  {
    key: "material",
    value: "index1.6",
    displayName: "1.60",
  },
  {
    key: "material",
    value: "thinLens",
    displayName: "1.67",
  },
  {
    key: "material",
    value: "ultraThin",
    displayName: "1.74",
  },
  {
    key: "treatment",
    value: "transitions",
    displayName: "Transitions",
  },
  {
    key: "treatment",
    value: "tinted",
    displayName: "Tint",
  },
  {
    key: "ultimaTech",
    value: "stabilizeTechnology",
    displayName: "Stabilize",
    animationSource: StabilizeAnimation,
    benefits: [
      "Higher image stability for reduce swim effect",
      "Improve distance peripheral visual acuity",
      "Improved visual performance in intermediate and near.",
    ],
  },
  {
    key: "ultimaTech",
    value: "eye-GazeTechnology",
    displayName: "Eye-Gaze",
    animationSource: EyeGaze,
    benefits: [
      "Wider fields of view",
      "Precise and comforatble vision at every distance and direction of gaze",
      "A more consistent wearer exprience",
      "Near elimination of peripheral blur.",
    ],
  },
  {
    key: "ultimaTech",
    value: "blendTechnology",
    displayName: "Blend Technology",
    animationSource: BlendTechAnimation,
    benefits: [
      "Improved vision in all zones",
      "Extended reading area",
      "Easier adaptation.",
    ],
  },
];

export const UltimaVariationsData = [
  {
    key: "Ultima-intermediate",
    value: "Ultima-intermediate",
    displayName: "Intermediate",
  },
  {
    key: "Ultima",
    value: "Ultima",
    displayName: "Balanced",
  },
  {
    key: "Ultima-near",
    value: "Ultima-near",
    displayName: "Near",
  },
  {
    key: "Ultima-distance",
    value: "Ultima-distance",
    displayName: "Distance",
  },
];
