import { LensTechnogolyData } from "./commonInterfaces";
import initiaAnimation from "../assets/animations/initia.mp4";

export const initiaData: LensTechnogolyData[] = [
  {
    key: "techName",
    value: "Initia",
    displayName: "Initia",
    // benefits: [
    //   "Wider side-to-side visual fields",
    //   "Reduced distortions for clearer vision",
    //   "Enhanced comfort for your eyes",
    //   "Larger and more comfortable reading area",
    //   "Quicker and easier adjustment",
    //   "Clear vision in every direction you look",
    //   "Fully customized for each individual user",
    // ],
    animationSource: initiaAnimation,
    isLensColor: false,
  },
  {
    key: "overallRating",
    value: 6,
    displayName: "Over All Rating",
  },
  {
    key: "classic",
    value: 1.49,
    displayName: "Refractive Index",
  },
  {
    key: "classic",
    value: "-8.00 to +8.00 Upto -4 cyl",
    clearRange: { min: -5.0, max: 5.0 },
    displayName: "Power Range",
  },
  {
    key: "classic",
    value: false,
    displayName: "Shatter Resistance",
  },
  {
    key: "classic",
    value: "",
    displayName: "Thickness Compared to Classic Lens",
  },
  {
    key: "classic",
    value: false,
    displayName: "Suitability For Rimless Frames",
  },
  {
    key: "classic",
    value: "",
    displayName: "High Power Suitability",
  },
  {
    key: "polycarbonate",
    value: "",
    displayName: "Refractive Index",
  },
  {
    key: "polycarbonate",
    value: "-9.00 to +6.50 Upto -5 cyl",
    displayName: "Power Range",
    defaultRange: { min: -9.0, max: 6.5 },
  },
  {
    key: "polycarbonate",
    value: true,
    displayName: "Shatter Resistance",
  },
  {
    key: "polycarbonate",
    value: "",
    displayName: "Thickness Compared to Classic Lens",
  },
  {
    key: "polycarbonate",
    value: true,
    displayName: "Suitability For Rimless Frames",
  },
  {
    key: "polycarbonate",
    value: 3,
    displayName: "High Power Suitability",
  },
  {
    key: "thinLens",
    value: "",
    displayName: "Refractive Index",
  },
  {
    key: "thinLens",
    value: "-13.00 to +8.50 Upto -6 cyl",
    defaultRange: { min: -11, max: 7 },
    clearRange: { min: -13, max: 8.5 },
    displayName: "Power Range",
  },
  {
    key: "thinLens",
    value: false,
    displayName: "Shatter Resistance",
  },
  {
    key: "thinLens",
    value: "",
    displayName: "Thickness Compared to Classic Lens",
  },
  {
    key: "thinLens",
    value: true,
    displayName: "Suitability For Rimless Frames",
  },
  {
    key: "thinLens",
    value: "",
    displayName: "High Power Suitability",
  },
  {
    key: "ultraThin",
    value: "",
    displayName: "Refractive Index",
  },
  {
    key: "ultraThin",
    value: "-16.00 to +9.00 Upto -4 cyl",
    clearRange: { min: -16, max: 9 },
    displayName: "Power Range",
  },
  {
    key: "ultraThin",
    value: false,
    displayName: "Shatter Resistance",
  },
  {
    key: "ultraThin",
    value: "",
    displayName: "Thickness Compared to Classic Lens",
  },
  {
    key: "ultraThin",
    value: true,
    displayName: "Suitability For Rimless Frames",
  },
  {
    key: "ultraThin",
    value: "",
    displayName: "High Power Suitability",
  },
  {
    key: "index1.53",
    value: "",
    displayName: "Refractive Index",
  },
  {
    key: "index1.53",
    value: "-9.00 to +6.50 Upto -5 cyl",
    defaultRange: { min: -9, max: 6.5 },
    displayName: "Power Range",
  },
  {
    key: "index1.53",
    value: "",
    displayName: "Shatter Resistance",
  },
  {
    key: "index1.53",
    value: "",
    displayName: "Thickness Compared to Classic Lens",
  },
  {
    key: "index1.53",
    value: "",
    displayName: "Suitability For Rimless Frames",
  },
  {
    key: "index1.53",
    value: "",
    displayName: "High Power Suitability",
  },
  {
    key: "index1.6",
    value: "",
    displayName: "Refractive Index",
  },
  {
    key: "index1.6",
    value: "-9.00 to +6.50 Upto -5 cyl",
    defaultRange: { min: -9, max: 6.5 },
    displayName: "Power Range",
  },
  {
    key: "index1.6",
    value: "",
    displayName: "Shatter Resistance",
  },
  {
    key: "index1.6",
    value: "",
    displayName: "Thickness Compared to Classic Lens",
  },
  {
    key: "index1.6",
    value: "",
    displayName: "Suitability For Rimless Frames",
  },
  {
    key: "index1.6",
    value: "",
    displayName: "High Power Suitability",
  },
  {
    key: "coating",
    value: "hc",
    displayName: "HC",
  },
  {
    key: "coating",
    value: "hmc",
    displayName: "HMC",
  },
  {
    key: "coating",
    value: "blueTech+",
    displayName: "BlueTech+",
  },
  {
    key: "coating",
    value: "drivez",
    displayName: "DrivEZ",
  },
  {
    key: "coating",
    value: "clearSight",
    displayName: "ClearSight",
  },
  {
    key: "material",
    value: "classic",
    displayName: "1.49",
  },
  {
    key: "treatment",
    value: "tinted",
    displayName: "Tint",
  },
];
