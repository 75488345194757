import React, { useState } from "react";
import {
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
} from "@mui/material";

interface RadioButtonsProps {
  setSelectedCategory: (event: string) => void;
  selectedCategory: string;
}
const RadioButton: React.FC<RadioButtonsProps> = ({
  setSelectedCategory,
  selectedCategory,
}) => {
  const handleCategoryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedCategory(event.target.value);
  };

  return (
    <div className="radio-btn-selection">
      <FormControl
        component="fieldset"
        className="radio-btn-selection__radio-formcontrol"
      >
        <RadioGroup
          row
          value={selectedCategory}
          onChange={handleCategoryChange}
          style={{ justifyContent: "center", gap: "16px" }}
        >
          <FormControlLabel
            value="biFocal"
            control={<Radio />}
            label="Bi Focal"
          />
          <FormControlLabel
            value="officePro"
            control={<Radio />}
            label="Office Pro"
          />
          <FormControlLabel
            value="progressive"
            control={<Radio />}
            label="Progressive"
          />
        </RadioGroup>
      </FormControl>
    </div>
  );
};

export default RadioButton;
