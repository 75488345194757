import React, { useRef, useEffect, useState } from "react";
import * as THREE from "three";
import { BackgroundENVModel } from "../../utils/commonInterfaces";
import { useFrame, useThree } from "@react-three/fiber";
import {
  SphereGeometry,
  MeshBasicMaterial,
  VideoTexture,
  BackSide,
} from "three";
import Loader from "../Loader";

interface LensLoaderProps {
  BackgroundENVModels: BackgroundENVModel;
}

const VideoBackground: React.FC<LensLoaderProps> = ({
  BackgroundENVModels,
}) => {
  const [texture, setTexture] = useState<THREE.Texture | null>(null);
  const meshRef = useRef<THREE.Mesh | null>(null);
  const { gl, scene } = useThree();
  useEffect(() => {
    if (BackgroundENVModels.type === "video") {
      const video = document.createElement("video");
      video.src = `/videos/${BackgroundENVModels.model}.mp4`;
      video.crossOrigin = "Anonymous";
      video.loop = true;
      video.muted = true;
      video.play().catch((error) => {});
      const videoTexture = new VideoTexture(video);
      setTexture(videoTexture);
    }
  }, [BackgroundENVModels.model, BackgroundENVModels.type]);
  gl.toneMappingExposure = 1.2;
  const geometry = new SphereGeometry(200, 60, 40);
  const material = new MeshBasicMaterial({
    map: texture,
    side: BackSide,
  });

  useFrame(({ camera }) => {
    if (meshRef.current) {
      meshRef.current.position.copy(camera.position);
    }
  });

  return texture ? (
    <mesh ref={meshRef} geometry={geometry} material={material} />
  ) : (
    <Loader theme="white_theme" />
  );
};

export default VideoBackground;
