import React, { createContext, useState, ReactNode } from "react";

export interface InfoAnimation {
  benefits?: string[];
  displayName?: string;
  animationSource?: string;
  isCompareTechnology?: boolean;
  addOn?: boolean;
  title?: string;
}

interface InfoAnimContextType {
  selectedInfoAnim: InfoAnimation[];
  setSelectedInfoAnim: (animations: InfoAnimation[]) => void;
}

export const InfoAnimContext = createContext<InfoAnimContextType>({
  selectedInfoAnim: [],
  setSelectedInfoAnim: () => {},
});

interface InfoAnimProviderProps {
  children: ReactNode;
}

export const InfoAnimProvider: React.FC<InfoAnimProviderProps> = ({
  children,
}) => {
  const [selectedInfoAnim, setSelectedInfoAnim] = useState<InfoAnimation[]>([]);

  return (
    <InfoAnimContext.Provider value={{ selectedInfoAnim, setSelectedInfoAnim }}>
      {children}
    </InfoAnimContext.Provider>
  );
};
