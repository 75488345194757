import { LensTechnogolyData } from "../../utils/commonInterfaces";
import { InfoAnimContext } from "../../context/infoAnimContext";
import { useContext } from "react";

interface TechProps {
  avTechs: LensTechnogolyData[];
}

const Technologies: React.FC<TechProps> = ({ avTechs }) => {
  const infoAnimContext = useContext(InfoAnimContext);

  const handleAnimationChange = (item: LensTechnogolyData) => {
    if (item.key === "prTech" && item.value !== "") {
      const newPreTechData = {
        benefits: item.benefits!,
        displayName: item.displayName!,
        animationSource: item.animationSource!,
        addOn: true,
        title: item.displayName
      };
      infoAnimContext.setSelectedInfoAnim([newPreTechData]);
    } else if (item.key === "ultimaTech" && item.value !== "") {
      const newUltimaTechData = {
        benefits: item.benefits!,
        displayName: item.displayName!,
        animationSource: item.animationSource!,
        addOn: true,
        title: item.displayName
      };
      infoAnimContext.setSelectedInfoAnim([newUltimaTechData]);
    }
  };

  return (
    <div className="tech__tech-wrapper">
      {avTechs.map(
        (tech: LensTechnogolyData, index) =>
          tech.value !== "" && (
            <button
              type="button"
              key={index}
              className="btn btn-info tech_btn"
              onClick={() => handleAnimationChange(tech)}
            >
              {tech.displayName}
            </button>
          )
      )}
    </div>
  );
};

export default Technologies;
