import React, { useContext, useEffect, useState } from "react";
import { BackgroundENVModel } from "../../utils/commonInterfaces";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import { InfoAnimContext } from "../../context/infoAnimContext";
import { TechContext } from "../../context/technologyContext";
import Technologies from "../Technologies";
import { LensTechnogolyData } from "../../utils/commonInterfaces";
interface BackgroundSelectionProps {
  BackgroundENVModels: BackgroundENVModel[];
  ChnageBackgroundENV: (ENVName: string) => void;
  activeENV: string;
  isBookObject: boolean;
  setIsBookObject: (isBookObject: boolean) => void;
  isMobileObject: boolean;
  setIsMobileObject: (isBookObject: boolean) => void;
}

const BackgroundSelection: React.FC<BackgroundSelectionProps> = ({
  BackgroundENVModels,
  ChnageBackgroundENV,
  activeENV,
  setIsBookObject,
  setIsMobileObject,
}) => {
  const [footerShow, setfooterShow] = useState<boolean>(true);
  const infoAnimContext = useContext(InfoAnimContext);
  const techContext = useContext(TechContext);
  const [lensParamsList, setLensParamsList] = useState<LensTechnogolyData[]>(
    []
  );
  const toggleVisibility = () => {
    setfooterShow(!footerShow);
  };
  useEffect(() => {
    setfooterShow(false);
    if (!techContext?.technology[0].value?.startsWith("Neo")) {
      setIsBookObject(false);
      setIsMobileObject(false);
    }
  }, [infoAnimContext, techContext]);

  const selectBackground = (item: string) => {
    if (item !== "Office-meeting-room") {
      setIsBookObject(false);
      setIsMobileObject(false);
    }
    ChnageBackgroundENV(item);
  };

  useEffect(() => {
    if (techContext) {
      let techData = techContext.technology;
      let tmpPrTechs = techData.filter((data) => data.key === "prTech");
      let tmpUltimaTechs = techData.filter((data) => data.key === "ultimaTech");
      let combinedTechs = [...tmpPrTechs, ...tmpUltimaTechs];
      setLensParamsList(combinedTechs);
    }
  }, [techContext]);

  return (
    <>
      <section
        className={`main-footer ${
          footerShow ? "main-footer-active" : ""
        } ${"show"}`}
      >
        <div className="main-footer_tool">
          <div className="main-footer_tool-1">
            <Technologies avTechs={lensParamsList} />
            <div className="main-footer_center">
              <button
                type="button"
                className="main-footer_list-collapse"
                onClick={toggleVisibility}
              >
                {footerShow ? <IoIosArrowDown /> : <IoIosArrowUp />}
              </button>
            </div>
          </div>
        </div>
        <div
          className={`main-footer_wrapper-btn-conatiner ${
            footerShow ? "footer-active" : ""
          }`}
        >
          <div className="main-footer_panoramas">
            {BackgroundENVModels.map((item, index) => (
              <button
                type="button"
                className={`main-footer_btn-env-list ${
                  activeENV === item.name ? "active-btn" : ""
                }`}
                key={index}
                onClick={() => selectBackground(item.name)}
                style={{
                  backgroundImage: `url(${item.imagePath})`,
                  backgroundSize: "cover",
                }}
              ></button>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default BackgroundSelection;
