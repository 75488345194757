import React, { createContext, useState } from 'react';
import { Quaternion, Vector3 } from 'three';

// Create CameraContext
export const CameraContext = createContext({});

export const CameraProvider: React.FC<any> = ({ children }) => {
  const [cameraPosition, setCameraPosition] = useState(new Vector3(0, 0, 2.5));
  const [cameraQuaternion, setCameraQuaternion] = useState(new Quaternion());

  return (
    <CameraContext.Provider value={{ cameraPosition, setCameraPosition, cameraQuaternion, setCameraQuaternion }}>
      {children}
    </CameraContext.Provider>
  );
};
