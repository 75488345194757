import React, { FC } from "react";
import { Html } from "@react-three/drei";
interface LoaderProps {
  theme: string;
}

const Loader: React.FC<LoaderProps> = ({ theme }) => {
  return (
    <Html>
      <span className={`loader ${theme}`}>T&nbsp;TAN</span>
    </Html>
  );
};

export default Loader;
