import React, { createContext, useState, ReactNode } from "react";

interface CoatingContextType {
  selectedCoating: string;
  setSelectedCoating: (tech: string) => void;
}

export const CoatingContext = createContext<CoatingContextType>({
  selectedCoating: "",
  setSelectedCoating: () => {},
});

interface CoatingProviderProps {
  children: ReactNode;
}

export const CoatingProvider: React.FC<CoatingProviderProps> = ({
  children,
}) => {
  const [selectedCoating, setSelectedCoating] = useState<string>("");

  return (
    <CoatingContext.Provider value={{ selectedCoating, setSelectedCoating }}>
      {children}
    </CoatingContext.Provider>
  );
};
