import * as React from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import InfoLayout from "../InfoLayout";
import { InfoAnimation } from "../../context/infoAnimContext";
import DialogTitle from "@mui/material/DialogTitle";

interface AnimationDialogProps {
  animationInfo: InfoAnimation[];
  isCompareTech: boolean;
  setIsDialogOpen: (isDialogOpen: boolean) => void;
  isDialogOpen: boolean;
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const AnimationDialog: React.FC<AnimationDialogProps> = ({
  animationInfo,
  isCompareTech,
  setIsDialogOpen,
  isDialogOpen,
}) => {
  const handleClickOpen = () => {
    setIsDialogOpen(true);
  };
  const handleClose = () => {
    setIsDialogOpen(false);
  };

  return (
    <React.Fragment>
      <BootstrapDialog
        className="customized-dialog"
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={isDialogOpen}
      >
        <DialogTitle
          sx={{ m: 0, p: 2 }}
          id="customized-dialog-title"
          className="customized-dialog__dialog-title"
        >
          {/* {animationInfo[0]?.title!} */}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          className="customized-dialog__close-icon"
          sx={(theme: any) => ({
            position: "absolute",
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers className="customized-dialog__dialog-content">
          <InfoLayout
            animationInfo={animationInfo}
            // isCompare={isCompare}
            isCompareTech={isCompareTech}
            // setIsCompare={setIsCompare}
          />
        </DialogContent>
      </BootstrapDialog>
    </React.Fragment>
  );
};
export default AnimationDialog;
