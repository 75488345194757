import React, { Suspense, useRef } from "react";
import {
  LensCategoryItem,
} from "../../utils/commonInterfaces";

interface UltimaVariationsProps {
  UltimaLensModelChange: (selectedLens: string) => void;
  ultimaVariationsData: LensCategoryItem[];
  ultimaSelectedTech: string;
}

const UltimaVariations: React.FC<UltimaVariationsProps> = ({
  UltimaLensModelChange,
  ultimaVariationsData,
  ultimaSelectedTech
}) => {

  return (
    <div className="ultima-selected_tech">
      {ultimaVariationsData.map(
        (tech, index) =>
          tech.value !== "" && (
            <button
              key={index}
              className={`btn btn-info ultima-selected_btn ${ultimaSelectedTech === tech.value ? "ultima-selected_active" : ""}`}
              onClick={() => UltimaLensModelChange(tech.value)}
            >
              {tech.displayName}
            </button>
          )
      )}
    </div>
  );
};

export default UltimaVariations;
