import React, { useEffect, useState } from "react";
import BackgroundScene from "../BackgroundScene";
import { BackgroundENVModel } from "../../utils/commonInterfaces";
import VideoBackground from "../VideoBackground";
interface LensLoaderProps {
  BackgroundENVModels: BackgroundENVModel;
}

const Background: React.FC<LensLoaderProps> = ({ BackgroundENVModels }) => {
  const [isVideo, setIsVideo] = useState(false);
  useEffect(() => {
    if (BackgroundENVModels.type === "video") {
      setIsVideo(true);
    } else {
      setIsVideo(false);
    }
  }, [BackgroundENVModels]);

  return (
    <>
      {isVideo && BackgroundENVModels.type === "video" ? (
        <VideoBackground BackgroundENVModels={BackgroundENVModels} />
      ) : (
        <BackgroundScene BackgroundENVModels={BackgroundENVModels} />
      )}
    </>
  );
};

export default Background;
