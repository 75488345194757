import * as React from "react";
import { useState } from "react";
import { colorsData } from "../../utils/colors";
import { ColorEntry, ColorCategory } from "../../utils/commonInterfaces";
import { ColorContext } from "../../context/colorContext";

interface ColorPickerProps {
  colors?: keyof ColorCategory | string;
}

const ColorPicker: React.FC<ColorPickerProps> = ({ colors }) => {
  const [selectedColors, setSelectedColors] = useState<ColorEntry | null>(null);
  const colorContext = React.useContext(ColorContext);
  const colorArray: ColorEntry[] =
    (colors && colorsData[colors as keyof ColorCategory]) || [];

  const handleColorClick = (color: ColorEntry) => {
    if (selectedColors === color) {
      setSelectedColors(null);
      if (colorContext.selectedColor) {
        const clearColors = {
          ...colorContext.selectedColor,
          color1: "",
          color2: "",
        };
        colorContext.setSelectedColor(clearColors);
      }
    } else {
      setSelectedColors(color);
      colorContext.setSelectedColor(color);
    }
  };

  return (
    <>
      {colorArray.length > 0 && (
        <div className="control-pane">
          <div id="custom-content" className="row">
            {colorArray.map((color, index) => (
              <div key={index} className="col-3 e-rounded-wrap">
                <span
                  className="e-tile e-rounded-palette"
                  role="gridcell"
                  aria-label={color.value}
                  aria-selected={
                    selectedColors?.value === color.value ? "true" : "false"
                  }
                  tabIndex={0}
                  style={{ background: color.value }}
                  onClick={() => handleColorClick(color)}
                  title={color.name}
                ></span>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default ColorPicker;
