import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { BUTTON_LABELS } from "../../utils/constants";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";

interface TechinfoProps {
  benefits: string[];
}

const AccordionComponent: React.FC<TechinfoProps> = ({ benefits }) => {
  const [expand, setExpand] = React.useState(true);
  const toggleAccordion = () => {
    setExpand((prev) => !prev);
  };
  return (
    <div>
      {benefits && (
        <Accordion className="accordion" expanded={expand}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon className="accordion_icon" />}
            aria-controls="panel2-content"
            id="panel2-header"
            className="accordion_summary"
            onClick={toggleAccordion}
          >
            <Typography className="accordion_discription-label">
              {BUTTON_LABELS.ACCORDION_BENEFITS}
            </Typography>
          </AccordionSummary>
          <AccordionDetails className="accordion_details">
            <List className="accordion_ul-list">
              {benefits?.length > 0 &&
                benefits?.map((item, index) => (
                  <ListItem
                    sx={{ display: "list-item" }}
                    className="accordion_list"
                  >
                    {item}
                  </ListItem>
                ))}
            </List>
          </AccordionDetails>
        </Accordion>
      )}
    </div>
  );
};
export default AccordionComponent;
