import { LensCategoryItem } from "./commonInterfaces";
import { bifocalKTypeData } from "./kBiFocalData";
import { neoBinocsData } from "./neoBinocsData";
import { neoDigiData } from "./neoDigiData";
import { neoErgoData } from "./neoErgoData";
import { neoProData } from "./neoProData";
import { neoSpaceData } from "./neoSpaceData";
import { neoSyncData } from "./neoSyncData";
import { neoUnoData } from "./neoUnoData";
import { ultimaData } from "./ultimaData";
import { initiaData } from "./initiaData";
import { neoExpertData } from "./neoExpert";
import { drivEZData } from "./drivEZ";
import { aceBiFocalData } from "./aceBiFocalData";
import { dBiFocalData } from "./dBiFocalData";
import { officeProMeeting } from "./officeProMeeting";
import { officeProDesktop } from "./officeProDesktop";
import { officeProLaptop } from "./officeProLaptop";
import { bifocalKDitalData } from "./bifocalKDigitalData";
import { singleVisionData } from "./singleVisionData";
import { genXTData } from "./genXTData";

export const lensCategories: LensCategoryItem[] = [
  {
    key: "singleVision",
    value: "singleVisionRx",
    displayName: "Single Vision",
    data: singleVisionData,
  },
  {
    key: "singleVision",
    value: "genXT",
    displayName: "GenXT",
    data: genXTData,
  },
  {
    key: "singleVision",
    value: "drivEZ",
    displayName: "DrivEZ",
    data: drivEZData,
  },
  {
    key: "biFocal",
    value: "dBiFocal",
    displayName: "D Bi Focal",
    data: dBiFocalData,
  },
  {
    key: "biFocal",
    value: "kBiFocal",
    displayName: "K Bi Focal",
    data: bifocalKTypeData,
  },
  // {
  //   key: "biFocal",
  //   value: "kBiFocalDigital",
  //   displayName: "K Bi Focal (Digital)",
  //   data: bifocalKDitalData,
  // },
  // {
  //   key: "biFocal",
  //   value: "aceBiFocal",
  //   displayName: "Ace Bi Focal",
  //   data: aceBiFocalData,
  // },
  {
    key: "officePro",
    value: "officeProLaptop",
    displayName: "Laptop",
    data: officeProLaptop,
  },
  {
    key: "officePro",
    value: "officeProDesktop",
    displayName: "Desktop",
    data: officeProDesktop,
  },
  {
    key: "officePro",
    value: "officeProMeeting",
    displayName: "Meeting",
    data: officeProMeeting,
  },

  {
    key: "progressive",
    value: "initia",
    displayName: "Initia",
    data: initiaData,
  },
  {
    key: "progressive",
    value: "neoDigi",
    displayName: "Neo Digi",
    data: neoDigiData,
  },
  {
    key: "progressive",
    value: "neoUno",
    displayName: "Neo Uno",
    data: neoUnoData,
  },
  {
    key: "progressive",
    value: "neoPro",
    displayName: "Neo Pro",
    data: neoProData,
  },
  {
    key: "progressive",
    value: "neoExpert",
    displayName: "Neo Expert",
    data: neoExpertData,
  },
  {
    key: "progressive",
    value: "drivez",
    displayName: "DrivEZ",
    data: drivEZData,
  },
  {
    key: "progressive",
    value: "neoSpace",
    displayName: "Neo Space",
    data: neoSpaceData,
  },
  {
    key: "progressive",
    value: "neoBinocs",
    displayName: "Neo Binocs",
    data: neoBinocsData,
  },
  {
    key: "progressive",
    value: "neoErgo",
    displayName: "Neo Ergo",
    data: neoErgoData,
  },
  {
    key: "progressive",
    value: "neoSync",
    displayName: "Neo Sync",
    data: neoSyncData,
  },
  {
    key: "progressive",
    value: "ultima",
    displayName: "Ultima",
    data: ultimaData,
  },
];
