import React, { createContext, useState, ReactNode } from "react";

interface MaterialContextType {
  selectedMaterial: string;
  setSelectedMaterial: (material: string) => void;
}

export const MaterialContext = createContext<MaterialContextType>({
  selectedMaterial: "",
  setSelectedMaterial: () => {},
});

interface MaterialProviderProps {
  children: ReactNode;
}

export const MaterialProvider: React.FC<MaterialProviderProps> = ({
  children,
}) => {
  const [selectedMaterial, setSelectedMaterial] = useState<string>("classic");

  return (
    <MaterialContext.Provider value={{ selectedMaterial, setSelectedMaterial }}>
      {children}
    </MaterialContext.Provider>
  );
};
